import checkIcon from '../assets/pic/check.svg';
import errorIcon from '../assets/pic/error.svg';
import infoIcon from '../assets/pic/info.svg';
import warningIcon from '../assets/pic/warning.svg';

export const TOAST_PROPERTIES = [
  {
    id: Math.floor((Math.random() * 101) + 1),
    title: 'Sent',
    description: 'Order sent.',
    backgroundColor: '#5cb85c',
    icon: checkIcon,
    show: true
  },
  {
    id: Math.floor((Math.random() * 101) + 1),
    title: 'Danger',
    description: 'This is an error toast component',
    backgroundColor: '#d9534f',
    icon: errorIcon,
    show: true
  },
  {
    id: Math.floor((Math.random() * 101) + 1),
    title: 'Favorite',
    description: 'Favorite added.',
    backgroundColor: '#5bc0de',
    icon: infoIcon,
    show: true
  },
  {
    id: Math.floor((Math.random() * 101) + 1),
    title: 'Warning',
    description: 'This is a warning toast component',
    backgroundColor: '#f0ad4e',
    icon: warningIcon,
    show: true
  }
];