import React, { useState, useEffect } from 'react'
import { Text } from '../languages/Language';
import LoginModal from "../shop/LoginModal";
import { ReactComponent as UserIcon } from '../assets/pic/user-icon.svg';
import { ReactComponent as HeadquarterIcon } from '../assets/pic/headquarter.svg';
import { ReactComponent as ShippingIcon } from '../assets/pic/shipping.svg';
import { VENDORID, VERSION } from '../constants';
import Bill from '../shop/Bill';
import Order from '../shop/Order';
import LanguageSelector from '../languages/LanguageSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fa8, faAddressBook, faAngleRight, faArrowAltCircleRight, faArrowRight, faArrowsToDot, faDotCircle, faHome, faMoneyBill, faPaperPlane, faPaperclip, faShoppingBasket } from '@fortawesome/free-solid-svg-icons';
import Datenschutz from '../pages/Datenschutz';
import AGB from '../pages/AGB';
import Contact from '../pages/Contact';
import AboutUs from '../pages/AboutUs';
import Impressum from '../pages/Impressum';
import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons/faArrowRotateRight';

export default function UserProfile(props) {
    const [showModal, setShowModal] = React.useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        setData(JSON.parse(sessionStorage.getItem('user')))
    }, [showModal]);

    function showUserProfile() {
        setShowModal(true)

    };

    const parentHandleToken = (isLogged) => {

        props.setAppToken(isLogged);
    };


    return (
        <>

            <div className="self-center text-white cursor-pointer text-xxs lg:text-sm hover:opacity-75"
                onClick={() => showUserProfile()}>
                {data
                    ? <div className='flex flex-row items-center'>
                        <UserIcon
                            className="ml-1 w-6 h-6 fill-white stroke-white"
                        />
                        <div className='hidden sm:block text-sm'>{data[0].userName}</div>
                    </div>
                    : null}
            </div>
            {showModal ? (
                <>
                    <div
                        className="text-slate-900 top-12 fade In justify-end w-full
                        flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none
                        focus:outline-none"
                    >
                        <div className="relative  flex w-96 h-[550px] justify-end">
                            {/*content*/}
                            <div className="border-2 border-company-primary-color shadow-lg relative flex flex-col
                             w-full h-full bg-white outline-none focus:outline-none">

                                {/*header*/}
                                <div className="flex items-start justify-between p-2 border-b
                                 border-solid border-slate-200 rounded-t">
                                    <button
                                        className="p-1 mr-auto bg-transparent border-0 text-black
                                         opacity-50 float-right text-xl leading-none font-semibold
                                          outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="bg-transparent text-black h-6 w-6 text-xl
                                         block outline-none focus:outline-none">
                                            &#10006;
                                        </span>
                                    </button>
                                    <div className='mt-1 mr-3'>
                                    <LanguageSelector></LanguageSelector>
                                    </div>

                                </div>
                                {/*body*/}
                                <div className='flex flex-row w-full justify-between px-6'>
                                    <div></div>


                                </div>
                                <div className='flex flex-row w-full h-full overflow-auto'>
                                    {!data ? <div className="flex w-96  h-96 items-center
                                    justify-center"><Text tid="NoData" /></div>:null}

                                    {data ?
                                        <div className='flex flex-row w-full overflow-auto h-full'>

                                            {data
                                                ? <div className=' w-full h-full grid grid-cols-1 content-between'>
                                                    <div className='flex gap-2 flex-col'>

                                                        <div
                                                        onClick={()=>{
                                                            props.handleOpen();
                                                            setShowModal(false)
                                                         }}
                                                        className='text-black  items-center py-3 block md:hidden flex flex-row bg-company-primary-color-100 p-2 gap-1 hover:opacity-75'>
                                                        <FontAwesomeIcon icon={faHome}  className=" pl-1 w-5 h-5 text-grey stroke-company-primary-color text-company-primary-color sm:text-white"/>
                                                        <div className='pl-1 text-sm'><Text tid="Home"/></div>
                                                        </div>

                                                        <div className='text-black items-center block md:hidden flex flex-row bg-company-primary-color-100 p-1 gap-1'>
                                                        <Order onWait={props.onWait} onLoadSavedSlip={() => props.onLoadSavedSlip()} />
                                                        </div>

                                                        <div className='text-black items-center block md:hidden flex flex-row bg-company-primary-color-100 p-1 gap-1'>
                                                         <Bill />
                                                        </div>

                                                        <div className='flex flex-row bg-company-primary-color-100 p-2 gap-1' >
                                                            <UserIcon className="w-6 h-6 mx-0.5 fill-company-primary-color stroke-company-primary-color" />
                                                            <div>
                                                                <div className='text-sm '>
                                                                    {data[0].userName}
                                                                </div>
                                                                <div className='text-sm '>
                                                                    {sessionStorage.getItem('email')}
                                                                </div>
                                                                <div className='text-sm '>
                                                                    {sessionStorage.getItem('customerCode')}
                                                                </div>
                                                                <div className='text-sm '>
                                                                    {VENDORID}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='flex flex-row bg-company-primary-color-100 p-2 gap-1' >
                                                        <HeadquarterIcon className="w-5 h-5 mx-1 stroke-company-primary-color"/>
                                                            <div className='text-sm '>
                                                                <div>
                                                                    {data[0].erpCustomerName1}
                                                                </div>
                                                                <div>
                                                                    {data[0].erpCustomerAddress1}
                                                                </div>
                                                                <div>
                                                                    {data[0].erpCustomerCityPostalCode}&nbsp;
                                                                    {data[0].erpCustomerCityName}
                                                                </div>
                                                                <div >
                                                                    {data[0].erpCustomerCountryName}
                                                                </div>
                                                            </div>
                                                        </div>

                                                         {
                                                            data[0].erpShippingAdrName1 &&
                                                            <div className='flex flex-row bg-company-primary-color-100 p-2 gap-1' >
                                                        <ShippingIcon className="w-5 h-5 mx-1 fill-company-primary-color"/>
                                                            <div className='text-sm '>
                                                                <div className='text-sm '>
                                                                    {data[0].erpShippingAdrName1}
                                                                </div>
                                                                <div>
                                                                    {data[0].erpShippingAdrName2}
                                                                </div>
                                                                <div>
                                                                    {data[0].erpShippingAdrAddress1}
                                                                </div>
                                                                <div>
                                                                    {data[0].erpShippingAdrAddress2}
                                                                </div>
                                                                <div>
                                                                    {data[0].erpShippingAdrPostalCode}&nbsp;
                                                                    {data[0].erpShippingAdrCityName}
                                                                </div>
                                                                <div>
                                                                    {data[0].erpShippingAdrCountryCode}&nbsp;
                                                                    {data[0].erpShippingAdrCountryName}
                                                                </div>
                                                            </div>
                                                        </div>
                                                         }


                                                         <div className='space-y-2 block md:hidden text-start justify-start bg-company-primary-color-100 p-2 gap-1 text-sm'>
                                                        <div className='flex flex-row items-center gap-1 '>
                                                        <FontAwesomeIcon className='text-company-primary-color w-5 h-5 mx-1' icon={faAngleRight}/>
                                                        <Impressum ShowImpressum={(value) => props.ShowImpressum(value)}/>
                                                            </div>
                                                        <div className='flex flex-row items-center gap-1 '>
                                                        <FontAwesomeIcon className='text-company-primary-color w-5 h-5 mx-1' icon={faAngleRight}/>
                                                        <Datenschutz ShowDatenschutz={(value) => props.ShowDatenschutz(value)}/>
                                                            </div>
                                                        <div className='flex flex-row items-center gap-1 '>
                                                        <FontAwesomeIcon className='text-company-primary-color w-5 h-5 mx-1' icon={faAngleRight}/>
                                                        <AGB ShowAGB={(value) => props.ShowAGB(value)}/>
                                                            </div>
                                                        <div className='flex flex-row items-center gap-1 '>
                                                        <FontAwesomeIcon className='text-company-primary-color w-5 h-5 mx-1' icon={faAngleRight}/>
                                                        <Contact ShowContact={(value) => props.ShowContact(value)}/>
                                                            </div>
                                                        <div className='flex flex-row items-center gap-1 '>
                                                        <FontAwesomeIcon className='text-company-primary-color w-5 h-5 mx-1' icon={faAngleRight}/>
                                                        <AboutUs ShowAboutUs={(value) => props.ShowAboutUs(value)}/>
                                                            </div>




                                                            </div>
                                                    </div>
                                                    <div className='h-6'></div>
                                                    <div className='w-full gap-2 flex flex-col'>

                                                        <div className='bg-company-primary-color-100 p-2 text-sm cursor-pointer'
                                                        >
                                                            <LoginModal
                                                                parentHandleToken={parentHandleToken}
                                                                onLogoutDeleteItemsAndCategories={() => props.onLogoutDeleteItemsAndCategories()}
                                                            />
                                                        </div>
                                                        <div className='bg-gray-200 flex justify-between p-2'>
                                                            <p className='text-very-small'>
                                                                Engineered by FALKE Bilgi Teknolojileri Ltd. Şti.
                                                            </p>
                                                            <p className='text-very-small'>
                                                            { VERSION }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            :null}
                                        </div>:null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    )
}
