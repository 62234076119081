import { DFTLNGCODE, URL as NEWURL } from '../constants';
import { VENDORID as NEWVENDORID } from '../constants';


export default function LoginToWebshop(customerCode, email, password) {
    const url = NEWURL;

    const requestOptions = {
        method: 'POST',
        headers: { 'Accept': 'application/json' },
        body: JSON.stringify({
            "serviceId": 1115, //service for User.Auth
            "customerCode": customerCode,
            "userEmail": email,
            "password": password,
            "lang": localStorage.getItem('rcml-lang')??DFTLNGCODE,
            //"clientTableRef": 1,
            "deviceManifacturer": "",
            "deviceModel": "",
            "deviceSerial": "",
            "deviceOSName": "",
            "deviceOSVersion": "",
            "vendorId": NEWVENDORID
        })
    };

    const fetchData = async () => {
        try {
            const response = await fetch(url, requestOptions);
            const json = await response.json();
            if (json.resCode === 200) {
                sessionStorage.setItem('token', json.dataSet[0]?.token);
                sessionStorage.setItem('discRate', json.dataSet[0]?.erpCustomerDiscRate);
                sessionStorage.setItem('user', JSON.stringify(json.dataSet));

                return json;
            }
            else return json;
        } catch (error) {
            console.log("error", error);
        }
    };

    return fetchData();
}
