import React from 'react'
import { Text } from '../languages/Language';

export default function Impressum(props) {

    function openImpressum() {
        console.log("hi")
        props.ShowImpressum(true)

    };


    return (
        <>
            <button
                className="flex items-center
        justify-center "
                type="button"
                onClick={() => openImpressum()}
            >
             <Text tid="Imprint" />
            </button>

        </>
    )
}
