import tr from './tr.json';
import en from './en.json';
import de from './de.json';
import fr from './fr.json';
import nl from './nl.json';

import dk from './dk.json';
import it from './it.json';
import sa from './sa.json';
import ir from './ir.json';

import FlaggeIconTurk from '../assets/pic/tr.png';
import FlaggeIconGerman from '../assets/pic/de.png';
import FlaggeIconBritain from '../assets/pic/gb.png';
import FlaggeIconFrance from '../assets/pic/fr.png';
import FlaggeIconNetherlands from '../assets/pic/nl.png';
import FlaggeIconDenmark from '../assets/pic/dk.png';
import FlaggeIconItaly from '../assets/pic/it.png';
import FlaggeIconSaudi from '../assets/pic/sa.png';
import FlaggeIconIreland from '../assets/pic/ir.png';

export const dictionaryList = {tr, de, nl, en, fr, dk, it, sa, ir };

export const languageOptions = {
  tr: FlaggeIconTurk ,
  de: FlaggeIconGerman,
  nl: FlaggeIconNetherlands,
  en: FlaggeIconBritain ,
  fr: FlaggeIconFrance,
  dk: FlaggeIconDenmark,
  it: FlaggeIconItaly,
  sa: FlaggeIconSaudi,
  ir: FlaggeIconIreland

};