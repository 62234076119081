import {URL as NEWURL} from '../constants';
import {VENDORID as NEWVENDORID} from '../constants';

export default function FetchCampaignFromAPI(itemRef) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Accept': 'application/json' },
        body: JSON.stringify({
            "serviceId": 1406,
            "token": sessionStorage.getItem('token'),
            "erpItemRef": itemRef,
            "vendorId": NEWVENDORID
        })
    };

    const fetchData = async () => {
        try {
            const response = await fetch(NEWURL, requestOptions);
            const json = await response.json();
            if (json.resCode === 200) {
                return json;
            }
            else return;
        } catch (error) {
            console.log("error", error);
        }
    };

    return fetchData();
}
