import { URL as NEWURL } from '../constants';
import { VENDORID as NEWVENDORID } from '../constants';

export default function fetchItemsFromAPI(token) {

    const url = NEWURL;

    const requestOptions = {
        method: 'POST',
        headers: { 'Accept': 'application/json' },
        body: JSON.stringify({
            "serviceId": 2015,
            "token": token,
            "vendorId": NEWVENDORID
        })
    };

    const fetchData = async () => {
        try {
            const response = await fetch(url, requestOptions);
            const json = await response.json();
            if (json.resCode === 200) {

                    sessionStorage.setItem('slipRef', json.slip[0]?.slipRef);
                    sessionStorage.setItem('items', JSON.stringify(json.slipLines));


                if (token === 1306) {
                    sessionStorage.setItem('allCategories', JSON.stringify(json.dataSet));
                }
                return json;
            }
            else return;
        } catch (error) {
            console.log("error", error);
        }
    };

    return fetchData();
}
