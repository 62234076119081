import React, { Component, useState } from "react";
import ProductList from "./ProductList";
import Carousel from "../pages/Carousel";
import { Text } from '../languages/Language';
import { BANNER as SHOWBANNER } from '../constants';

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: false
    }
  }

  onAdd = (item) => {

    this.props.onAdd(item)
  }

  onRemove = (item) => {

    this.props.onRemove(item)
  }


   sorting = (event) => {
    this.props.sortingFunc(event.target.value)

  };

  render() {

    return (
      <div
        className={`${this.props.isCategoryOpen ? "w-full text-inherit h-full hidden md:flex":
        (this.props.isCartOpen ? "w-full text-inherit h-full hidden md:flex" : "flex w-full text-inherit h-full ") }`}

      >
        {/* Mittelbereich */}
        <div
          className="flex-auto w-full h-full "
        >
          {
            this.props.productWebCategory
            && this.props.productWebCategory === "Home"
            && SHOWBANNER
            ?
            (
              this.props.isLoggedIn
                ? ""
                :
                <>
                  <h2
                    className="text-lg font-medium text-company-primary-color mb-2"
                  >
                    &nbsp;
                  </h2>
                  <Carousel />
                </>
            )
            :null
          }
          <div className="flex flex-row items-center">
              <h2
                className="text-md font-medium text-company-primary-color my-2 w-full sticky bg-white top-0"
              >
                <Text tid={`${this.props.productWebCategory  }` } />
                {this.props.productWebSubCategory&&<>
                 <span className="px-2">&#10140;</span>
                  <Text tid={`${this.props.productWebSubCategory }` } />
                </>}

              </h2>



              <div className=" ml-auto text-[10px] font-medium text-company-primary-color sticky bg-white">
              <div className="w-full max-w-xs mx-auto">
              <select
                id="sorting"
                name="sorting"
                value={this.props.sortingType}
                onChange={(this.sorting)}
                className="mt-1 block pl-3 pr-10 py-1 text-base border-gray-300 focus:outline-0 focus:ring-0  sm:text-sm rounded-md"
                >
                <option value="smart">        <Text tid="SmartSort"/> </option>
                <option value="alphabetical"> <Text tid="Alphabetic"/></option>
                <option value="itemCode">     <Text tid="ItemCode"/>  </option>
                <option value="itemPriceA">   <Text tid="ItemPriceA"/></option>
                <option value="itemPriceD">   <Text tid="ItemPriceD"/></option>
            </select>
        </div>
              </div>
          </div>

          <div
            className={`${this.props.productWebCategory !== "Home"
              ? "h-[calc(100%-2.5rem)]"
              : !SHOWBANNER
                ? "h-[calc(100%-2.5rem)]"
                : "h-[calc(100%-20.75rem)]"
              }
          flex border-t-4 border-b-4 border-r-2 w-full py-2 overflow-y-auto border-company-primary-color-100 `}
          >
            <ProductList
              items={this.props.items}
              allFavorites={this.props.allFavorites}
              allCampaigns={this.props.allCampaigns}
              allNewItems={this.props.allNewItems}
              allSold={this.props.allSold}
              itemSubCategoryName={this.props.itemSubCategoryName}
              notSold={this.props.notSold}
              productWebCategory={this.props.productWebCategory}
              itemCategoryName={this.props.itemCategoryName}
              productWebSubCategory={this.props.productWebSubCategory}
              onAdd={this.onAdd}
              onRemove={this.onRemove}
              allCategories={this.props.allCategories}
              onAddAllFavorites={this.props.onAddAllFavorites}
              onRemoveAllFavorites={this.props.onRemoveAllFavorites}
              handleChangeCount={this.props.handleChangeCount}
              value={this.props.value}
              filteredData={this.props.filteredData}
              sortingKey={this.props.sortingKey}
              ascending={this.props.ascending}
            />
          </div>
        </div>
        <div
          className="flex-none lg:flex flex-col sm:hidden"
        >
        </div>

      </div>

    );
  }
}

export default Content;
